
.count-down {
    display: flex;
    height: 43px;
    padding: 0 8px 0 21px;
    font-size: 16px;
    color: #ff3e6c;
    line-height: 43px;
    background-color: #262963;
    border-radius: 0 0 0 20px;
    .icon {
        font-size: 24px;
        margin-right: 11px;
    }
    .hint {
        color: #fff;
    }
    .time {
        text-indent: 7px;
    }
}

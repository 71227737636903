.student-container {
    &.default {
        background-color: #0c0e3f;
        .left-one-tab {
            .head-title {
                color: #edf1fd;
            }
            .user-info .username {
                color: #fff;
            }
            .one-menu .one-level-active {
                color: #fff;
                background-color: #262963;
            }
        }
        .right-box {
            background-color: #070932;
            .two-tab-box .head-two-tab {
                background-color: #0c0e3f;
                .two-tab-list {
                    background-color: #0c0e3f;
                    .tow-level-active {
                        color: #fff;
                        background-color: #070932;
                        .wider-box {
                            background-color: #070932;
                            &::before,
                            &::after {
                                background-color: #0c0e3f;
                            }
                        }
                    }
                }
            }
        }
    }
    &.light {
        background-color: #EEEFF3;
        .left-one-tab {
            .head-title {
                color: #333;
            }
            .user-info .username {
                color: #333;
            }
            .one-menu .one-level-item {
                color: #333;
                .iconfont {
                    color: #333;
                }
                &.one-level-active {
                    color: #fff;
                    background-color: #2821FC;
                    .iconfont {
                        color: #fff;
                    }
                }
            }
        }
        .right-box {
            background-color: #fff;
            .two-tab-box .head-two-tab {
                background-color: #EEEFF3;
                .two-tab-list {
                    background-color: #EEEFF3;
                    .tow-level-active {
                        background-color: #fff;
                        color: #4B45FF;
                        .wider-box {
                            background-color: #fff;
                            &::before,
                            &::after {
                                background-color: #EEEFF3;
                            }
                        }
                    }
                }
            }
        }
    }
}

$blurColor: #a4a4af;
* {
  padding: 0;
  margin: 0;
}
@import "./theme.scss";
.student-container {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .left-one-tab {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 230px;
    max-width: 230px;
    height: 100%;
    padding-bottom: 15px;
    .head-title {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .iconfont {
        margin: 0 10px;
        color: #4b45ff;
        font-size: 30px;
      }
      .title {
        font-weight: 400;
        font-size: 20px;
      }
    }
    .user-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 90px 0 105px 0;
      .avatar {
        width: 84px;
        height: 84px;
        border-radius: 50%;
        overflow: hidden;
        .avatar-img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
      }
      .username {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 18px;
        padding: 0 15px;
        font-size: 18px;
        color: #fff;
        .iconfont {
          margin-left: 9px;
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
    .one-menu {
      flex: 1;
      .one-level-item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 216px;
        height: 60px;
        padding-left: 40px;
        /* margin-top: 20px; */
        color: $blurColor;
        font-size: 18px;
        border-radius: 0 20px 20px 0;
        overflow: hidden;
        .iconfont {
          margin-right: 19px;
          color: #4742ef;
          font-size: 26px;
        }
      }
      .menu-item {
        margin-top: 20px;
        .two-menu-item {
          margin-top: 20px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .menu-item:first-child {
        margin-top: 0;
      }
      ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
  }
  .right-box {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
    .embed-page {
      box-sizing: border-box;
      width: 619px;
      min-width: 619px;
    }
    .two-tab-box {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      .head-two-tab {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 70px;
        overflow: hidden;
        .two-tab-list {
          box-sizing: border-box;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          height: 70px;
          .two-tab-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 144px;
            height: 50px;
            color: $blurColor;
            font-size: 18px;
            border-radius: 20px 20px 0 0;
          }
        }
      }
      .content {
        box-sizing: border-box;
        flex: 1;
        overflow: hidden;
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
          .el-scrollbar__view {
            height: 100%;
          }
        }
      }
    }
    .count-down {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.tow-level-active {
  position: relative;
  /* color: #fff !important; */
  .two-text {
    z-index: 99;
  }
  .wider-box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 184px;
    height: 20px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      z-index: 9;
      border-radius: 0 0 20px 0;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      z-index: 9;
      border-radius: 0 0 0 20px;
    }
  }
}
.wider {
  width: 160px;
}
